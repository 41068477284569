import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Послуги з прибирання Qivanaplay
			</title>
			<meta name={"description"} content={"Зробіть свій простір блискучим без особливих зусиль"} />
			<meta property={"og:title"} content={"Послуги з прибирання Qivanaplay"} />
			<meta property={"og:description"} content={"Зробіть свій простір блискучим без особливих зусиль"} />
			<meta property={"og:image"} content={"https://qivanaplay.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://qivanaplay.com/img/392758.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://qivanaplay.com/img/392758.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://qivanaplay.com/img/392758.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://qivanaplay.com/img/392758.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://qivanaplay.com/img/392758.png"} />
			<meta name={"msapplication-TileImage"} content={"https://qivanaplay.com/img/392758.png"} />
			<meta name={"msapplication-TileColor"} content={"https://qivanaplay.com/img/392758.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="flex-start"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline1"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Наша подорож до блиску
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
					Клінінгова компанія Qivanaplay розпочала свою діяльність з простої місії: зробити так, щоб оселі сяяли зсередини. Те, що почалося як маленька команда з великими мріями, перетворилося на провідну клінінгову компанію, відому своєю відданістю якості, деталям та навколишньому середовищу. Наш шлях прокладений вірою в те, що кожна оселя заслуговує на особливий затишок, а кожне прибирання - це можливість зробити чийсь день яскравішим.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://qivanaplay.com/img/3.jpg"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-8" background="--color-primary">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					width="100%"
				>
					<Image
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						bottom="0px"
						min-height="100%"
						src="https://qivanaplay.com/img/4.jpg"
						object-fit="cover"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-order="1"
				justify-content="flex-start"
				align-items="flex-start"
				padding="24px 24px 24px 24px"
			>
				<Box>
					<Text
						font="--base"
						lg-text-align="center"
						text-transform="uppercase"
						margin="0px 0px 8px 0px"
						color="--light"
						letter-spacing="2px"
					>
						Прикрасьте свій дім, прикрасьте своє життя з Qivanaplay
					</Text>
					<Text
						margin="0px 0px 36px 0px"
						color="--light"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Відмінність Qivanaplay
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--light" font="--lead" lg-text-align="center">
					У Qivanaplay ми не просто прибираємо - ми створюємо простір, який надихає на щастя та спокій. В основі всього, що ми робимо, лежить наша команда, яка складається з пристрасних, кваліфікованих професіоналів, які поділяють наше бачення досягнення досконалості в прибиранні будинків. Ми інвестуємо в постійне навчання та екологічно чисті рішення, тому що ми дбаємо про нашу команду, наших клієнтів і нашу планету.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Створіть історію своєї оселі з Qivanaplay
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Ваша оселя - це відображення вашої історії, і ми тут, щоб допомогти їй засяяти. Обрати Qivanaplay означає не просто найняти клінінгову компанію, а створити середовище, в якому розгортатимуться найкращі моменти вашого життя. Дозвольте нам подбати про деталі, щоб ви могли зосередитися на житті, коханні та створенні спогадів у просторі, який відчувається неповторно вашим.
				<br /><br />
				Розпочніть подорож до чистішої, яскравішої оселі вже сьогодні. Відчуйте різницю Qivanaplay, де кожне прибирання - це крок до ідеального тла для історії вашого життя.

			</Text>
			<Button
				font="--lead"
				margin="20px"
				type="link"
				text-decoration-line="initial"
				href="/contact"
			>
				Контакти
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652f9cc5dbe26f0020fd00a7"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});